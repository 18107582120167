<template lang="html" src="./pageOrders.template.vue"></template>

<style lang="scss" src="./pageOrders.scss"></style>

<script>
import { ApiErrorParser, CustomFilterForSearchDatatable, DataTableFilter } from '@cloudmanufacturingtechnologies/portal-components';
const i18nData = require('./pageOrders.i18n');

export default {
  name: 'PageOrders',
  components: { DataTableFilter },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      orders: [],
      loading: false,
      loadingTable: false,
      search: '',
      headers: [],
      ordersStatusFilter: [],
      invoiceStatusFilter: [],
      typeFilter: [],
      dataTableFilters: null
    };
  },
  created() {
    this.getSupplierOrders();
  },
  mounted() {
    this.headers = [
      {
        text: this.$t('status'),
        value: 'status',
        align: 'center',
        filter: (value, search, item) => {
          if (!this.ordersStatusFilter 
            || (this.ordersStatusFilter && this.ordersStatusFilter.length === 0)
          ) {
            return true;
          }
          if (this.ordersStatusFilter?.includes(value) && item.quote.dispatch.status !== 'CANCELED') {
            return true;
          }
          if(item.quote.dispatch.status === 'CANCELED' && this.ordersStatusFilter?.includes('CANCELED')) {
            return true;
          }
        },
      },
      {
        text: this.$t('Type'),
        value: 'public',
        width: '100px',
        align: 'center',
        sortable: false,
        filter: (value) => {
          if (this.typeFilter.length === 0) return true;
          if ((value && this.typeFilter.includes('public')) || (!value && this.typeFilter.includes('private')) ) return true;
        },
      },
      {
        text: this.$t('orderNumber'),
        value: 'invoiceNumber',
        align: 'center',
      },
      {
        text: this.$t('quantity'),
        value: 'quantity',
        align: 'center',
        sortable: false
      },
      {
        text: this.$t('technology'),
        value: 'technology',
        align: 'center',
        sortable: false
      },
      {
        text: this.$t('material'),
        value: 'material',
        align: 'center',
        sortable: false
      },
      {
        text: this.$t('PaymentStatus'),
        value: 'paymentStatus',
        align: 'center',
        sortable: false
      },
      {
        text: this.$t('Invoice'),
        value: 'invoice',
        align: 'center',
        sortable: false,
        filter: (value, search, item) => {
          if (!this.invoiceStatusFilter || this.invoiceStatusFilter.length === 0) {
            return true;
          }
          if(!this.isOrderWithBilling(item) && this.invoiceStatusFilter.includes('notApplicable')) {
            return true;
          }
          if (item.quote.dispatch.invoiceFile && this.invoiceStatusFilter.includes('uploaded')) {
            return true;
          }
          if (!item.quote.dispatch.invoiceFile && this.invoiceStatusFilter.includes('missing')) {
            return true;
          }
        },
      },
      { text: this.$t('created'),
        value: 'created',
        align: 'center' },
      {
        text: this.$t('action'),
        value: 'action',
        align: 'center',
        sortable: false,
      },
    ];
    this.dataTableFilters = {
      status: [
        {
          name: this.$t('NEW'),
          value: 'NEW',
          icon: 'far fa-clock',
          get selected() {
            return this.number > 0;
          },
          number: 0
        },
        {
          name: this.$t('INPROGRESS'), 
          value: 'INPROGRESS', 
          icon: 'fas fa-spinner',
          get selected() {
            return this.number > 0;
          },
          number: 0
        },
        {
          name: this.$t('DELIVERED'), 
          value: 'DELIVERED', 
          icon: 'fas fa-check', 
          iconColor: 'success',
          number: 0
        },
        {
          name: this.$t('CANCELED'), 
          value: 'CANCELED', 
          icon: 'fas fa-ban', 
          iconColor: 'error',
          number: 0
        }
      ],
      invoice: [
        {
          name: this.$t('Uploaded'),
          value: 'uploaded',
          icon: 'fas fa-file-pdf',
          iconColor: 'success',
          number: 0
        },
        {
          name: this.$t('Missing'), 
          value: 'missing', 
          icon: 'fas fa-file-pdf',
          iconColor: 'error',
          number: 0
        },
        {
          name: this.$t('NotApplicable'), 
          value: 'notApplicable', 
          icon: 'fas fa-file-pdf',
          iconColor: 'grey',
          number: 0
        },
      ],
      type: [
        {
          name: this.$t('CMT'),
          value: 'public', 
          img: 'logo_cut.png',
          number: 0,
        },
        {
          name: this.$t('PrivateWarehouse'),
          value: 'private', 
          iconColor: 'privateSupplier',
          icon: 'fas fa-warehouse',
          number: 0,
        }
      ]
    };
  },
  methods: {
    customFilter(items, search, filter) {
      return CustomFilterForSearchDatatable.customFilter(items, search, this);
    },
    getSupplierOrders() {
      this.loadingTable = true;
      /**
       * GET SUPPLIER ORDERS
       */
      this.$apiInstance
        .getSupplierOrders(this.$route.params.supplierUUID)
        .then((data) => {
          for(const key in this.dataTableFilters) {
            for(const filter of this.dataTableFilters[key]) {
              filter.number = 0;
            }
          }
          data.forEach((order) => {
            if(order?.quote?.dispatch?.status === 'CANCELED') {
              this.dataTableFilters.status[3].number += 1;
            } else {
              switch (order.status) {
              case 'NEW':
              case 'SCHEDULED':
                this.dataTableFilters.status[0].number += 1;
                break;
              case 'INPROGRESS':
                this.dataTableFilters.status[1].number += 1;
                break;
              case 'DELIVERED':
                this.dataTableFilters.status[2].number += 1;
                break;
              case 'CANCELED':
                this.dataTableFilters.status[3].number += 1;
                break;
              }
            }

            if(!this.isOrderWithBilling(order)) {
              this.dataTableFilters.invoice[2].number ++;
            } else if(order.quote.dispatch.invoiceFile) {
              this.dataTableFilters.invoice[0].number ++;
            } else {
              this.dataTableFilters.invoice[1].number ++;
            }
            this.dataTableFilters.type.find(x => (x.value === 'public' && order.public) || (x.value === 'private' && !order.public)).number++;
          });
          this.orders = data;
          this.orders.forEach((order) => {
            order.created = new Date(order.created).getTime();
          });
        })
        .catch((error) => {
          /**
           * ERROR GET SUPPLIER ORDERS
           */
          ApiErrorParser.parse(error);
        })
        .finally(() => {
          this.loadingTable = false;
          this.loading = false;
        });
    },
    updateFilter(updatedFilters) {
      this.ordersStatusFilter = updatedFilters.status;
      this.invoiceStatusFilter = updatedFilters.invoice;
      this.typeFilter = updatedFilters.type;
    },
    refreshPage() {
      this.loading = true;
      this.getSupplierOrders();
    },
    goToPageItem(item) {
      this.$router.push({name: 'Order', params: { supplierUUID: `${this.$route.params.supplierUUID}`, orderUUID: `${item._id}`}});
    },
    isOrderWithBilling(order) {
      return order.billing && order.status !== 'CANCELED' && order.quote.dispatch.status !== 'CANCELED';
    }
  },
};
</script>
